import { Box, Container } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import SignIn from "../../components/Signin";

const LoginPage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Container>
      {isAuthenticated ? (
        ""
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <SignIn />
        </Box>
      )}
    </Container>
  );
};

export default LoginPage;
