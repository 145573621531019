import "react-toastify/dist/ReactToastify.css";
import { ColorContext } from "./context/ColorContext";
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from "@mui/material";
import { darkTheme } from "./components/themes/dark";
import { lightTheme } from "./components/themes/light";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "./hooks/useAuth";
import Landingpage from "./pages/Landingpage";
import Navbar from "./components/Navbar";
import React, { useMemo, useState } from "react";
import useLocalStorage from "./hooks/useLocalStorage";
import "./App.css";
import Certificates from "./pages/certificates/Certificates";
import LoginPage from "./pages/login/LoginPage";
import AboutUs from "./pages/about/AboutUs";
import ContactUs from "./pages/contact/ContactUs";
import Reports from "./pages/reports/Reports";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { isAxiosError } from "axios";

const App = () => {
  const [themePreference, setThemePreference] = useLocalStorage("themePreference", "dark");
  const [mode, setMode] = useState<PaletteMode>(themePreference);
  const { user, onLogout } = useAuth();

  const theme = useMemo(() => createTheme(mode === "light" ? lightTheme : darkTheme), [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
        setThemePreference((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [setThemePreference],
  );

  const handleError = (error: Error) => {
    if (isAxiosError(error)) {
      if (error.response && error.response.status === 401) {
        onLogout();
        return;
      }
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: handleError,
    }),
    mutationCache: new MutationCache({
      onError: handleError,
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: false,
        retry: 1,
        staleTime: 5 * 10000,
      },
      mutations: {
        retry: 1,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={theme.palette.mode}
          />
          <Navbar />
          <Routes>
            <Route index element={<Landingpage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="contact" element={<ContactUs />} />
            <Route element={<ProtectedRoute redirectPath="/" isAllowed={!!user && user.role == "Customer"} />}>
              <Route path="profile" element={<p>Nothing here yet</p>} />
              <Route path="certificates" element={<Certificates />} />
              <Route path="report" element={<Reports />} />
            </Route>
            <Route path="*" element={<p>Theres nothing here: 404!</p>} />
          </Routes>
        </ThemeProvider>
      </ColorContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
